<template>
  <div class="main">
    <header>
      <div class="headerTop">{{ $fanyi('アカウント残高') }}</div>
      <div class="headerFooter">
        <div class="userPriceList flex" style="margin-top: 30px">
          <div class="userPriceContainer">
            <div class="userPriceLabel">
              {{ $fanyi('現在ご利用可能の金額') }}
            </div>
            <div class="userPriceValue flexAndCenter">
              <div>{{
                  $fun.yenNumSegmentation($store.state.userInfo.balance_availability)
                }}
              </div>
              <div>{{ $fanyi('円') }}</div>
            </div>
          </div>
          <div class="userPriceContainer">
            <div class="userPriceLabel">
              {{ $fanyi('入金確認待ち金額') }}
            </div>
            <div class="userPriceValue flexAndCenter">
              <div>{{ $store.state.userInfo.balance_recharge_audit }}</div>
              <div>{{ $fanyi('円') }}</div>
            </div>
          </div>
          <div class="userPriceContainer" v-if="$store.state.userInfo.balance_freezing!=0">
            <div class="userPriceLabel">
              {{ $fanyi('不可用金额') }}
            </div>
            <div class="userPriceValue flexAndCenter">
              <div>{{ $store.state.userInfo.balance_freezing }}</div>
              <div>{{ $fanyi('円') }}</div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <footer>
      <div class="footerTop">{{ $fanyi('振込先') }}</div>
      <div class="footerCenter flexAndCenter">
        <div v-for="(item,index) in bankList" :key="index" @click="updateBankIndex(index)"
             :class="selectBankIndex===index?'redBorderBankContainer flexAndCenter':'flexAndCenter'">
          <img :src="item.bankLogoUrl"/>
          <div v-show="selectBankIndex===index" class="selectBankIcon flexAndCenter">
            <img src="../../../../assets/checkMarkIcon.png">
          </div>
        </div>
      </div>
      <div class="footerBottom flexAndCenter">
        <div v-show="selectBankIndex===0" class="paypalRecharge flex">
          <div class="paypalInput">
            <el-input v-model.number="paypalRechargeValue" @input="paypalRechargeValueChange"
                      @keydown.native="channelInputLimit">
              <template slot="append">{{ $fanyi('円') }}</template>
            </el-input>
            <div class="PayPalBtn" @click="getPayPalPayLink()">paypalへ</div>
          </div>
          <div class="paypalRechargePrice" style="margin-top: 5px">
            <div class="flexAndCenter paypalRechargePriceTop">
              <div>{{ $fanyi('請求料金') }}：</div>
              <div class="u-line" style="width: 110px">{{ paypalPrice }}</div>
            </div>
            <div style="color: #B4272B;">({{ $fanyi('手数料') }}：{{
                this.$store.state.userInfo.PayPal_rate * 100
              }}%)
            </div>
          </div>
          <div class="paypalRechargeInstructions">
            <div>1、{{ $fanyi('チャージする金額を入力してください') }}。</div>
            <div>2、{{ $fanyi('ペイパル入金の場合、さらに4％手数料が必要になりますので、予めご了承ください') }}。</div>
          </div>
        </div>
        <div v-show="selectBankIndex===1" class="rakutenBankRecharge flex">
          <div>
            <div class="rakutenBankLeft">
              <div class="flexAndCenter">
                <div class="flex bankLabel">
                  <div class="redRequiredIcon">*</div>
                  <div>{{ $fanyi('着金予定日') }}：</div>
                </div>
                <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss" v-model="pay_reach_date"
                    type="datetime"></el-date-picker>
              </div>
              <div v-show="rakutenBankDateRequiredError===true" class="rakutenBankDateRequiredText">
                {{ $fanyi('*ついている項目は必ずご記入ください') }}
              </div>
            </div>
            <div class="rakutenBankLeft">
              <div class="flexAndCenter">
                <div style="margin-top: 8px">
                  <div class="flex bankLabel">
                    <div class="redRequiredIcon">*</div>
                    <div>{{ $fanyi('入金名義') }}：</div>
                  </div>
                  <div class="bankLabel" style="padding-right: 10px">{{ $fanyi('カタカナ記載') }}</div>
                </div>
                <el-autocomplete
                    v-model="rakutenName"
                    :fetch-suggestions="querySearchAsync"
                ></el-autocomplete>
              </div>
            </div>
            <div class="rakutenBankLeft" style="margin-top: 15px">
              <div class="flexAndCenter paypalInput">
                <div class="flex bankLabel">
                  <div class="redRequiredIcon">*</div>
                  <div>{{ $fanyi('入金額') }}：</div>
                </div>
                <el-input v-model.number="rakutenBankPrice" @input="extractAmountVerify"
                          @keydown.native="channelInputLimit">
                  <template slot="append">{{ $fanyi('円') }}</template>
                </el-input>
              </div>
              <div class="fontSize12 colorB4272B" style="margin-top: 9px;margin-left: 110px;">
                入金報告で、実際に入金する金額をご記入下さい。
              </div>
              <div class="fontSize12 colorB4272B" style="margin-left: 110px;">
                実際入金額と差異がありますと、入金反映が遅くなる原因になります。
              </div>
            </div>
            <div class="rakutenBankLeft" style="margin-top: 40px">
              <div class="flex">
                <div class="flexAndCenter bankLabel">
                  <div class="flex">{{ $fanyi('入金備考欄') }}：</div>
                </div>
                <el-input v-model="rakutenBankRemark"/>
              </div>
              <div class="fontSize12 colorB4272B" style="margin-top: 9px;margin-left: 110px;">{{
                  $fanyi('※入金報告の備考欄で質問を入れないでください')
                }}。
              </div>
              <div class="fontSize12 colorB4272B" style="margin-left: 110px;">{{
                  $fanyi('担当が異なりますので伝わりません')
                }}。
              </div>
            </div>
            <div class="submitBtn" @click="bankPayBalance()">{{ $fanyi('提出') }}</div>
          </div>
          <div style="margin-left: 184px">
            <div>
              <div class="flexAndCenter">
                <div class="labelName">銀行名：</div>
                <div>楽天銀行</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店番号：</div>
                <div>252</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店名：</div>
                <div>第二営業支店</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">預金種目：</div>
                <div>普通預金</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座番号：</div>
                <div>7977668</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座名義：</div>
                <div>ラクマート（カ）</div>
              </div>
            </div>
            <div class="colorB4272B" style="margin-top:59px">1、銀行入金する金額を入力してください</div>
            <div class="colorB4272B">2、銀行入金額は見積り総額と同じまたは上回るように入力と実際振り込
              みをお願い致します。
            </div>
            <div class="colorB4272B">3、入金名義について、必ず入金報告提出時のと同じなるようにお願い致
              します、違う場合、特定できず、入金反映が著しく遅くなってしまいます。
            </div>
          </div>
        </div>
        <div v-show="selectBankIndex===2" class="rakutenBankRecharge flex">
          <div>
            <div class="rakutenBankLeft">
              <div class="flexAndCenter">
                <div class="flex bankLabel">
                  <div class="redRequiredIcon">*</div>
                  <div>{{ $fanyi('着金予定日') }}：</div>
                </div>
                <el-date-picker v-model="pay_reach_date" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </div>
              <div v-show="rakutenBankDateRequiredError===true" class="rakutenBankDateRequiredText">
                {{ $fanyi('*ついている項目は必ずご記入ください') }}
              </div>
            </div>
            <div class="rakutenBankLeft">
              <div class="flexAndCenter">
                <div style="margin-top: 8px">
                  <div class="flex bankLabel">
                    <div class="redRequiredIcon">*</div>
                    <div>{{ $fanyi('入金名義') }}：</div>
                  </div>
                  <div class="bankLabel" style="padding-right: 10px">{{ $fanyi('カタカナ記載') }}</div>
                </div>
                <el-autocomplete
                    v-model="rakutenName"
                    :fetch-suggestions="querySearchAsync"
                ></el-autocomplete>
              </div>
            </div>
            <div class="rakutenBankLeft" style="margin-top: 15px">
              <div class="flexAndCenter paypalInput">
                <div class="flex bankLabel">
                  <div class="redRequiredIcon">*</div>
                  <div>{{ $fanyi('入金額') }}：</div>
                </div>
                <el-input v-model.number="rakutenBankPrice" @input="extractAmountVerify"
                          @keydown.native="channelInputLimit">
                  <template slot="append">{{ $fanyi('円') }}</template>
                </el-input>
              </div>
              <div class="fontSize12 colorB4272B" style="margin-top: 9px;margin-left: 110px;">
                入金報告で、実際に入金する金額をご記入下さい。
              </div>
              <div class="fontSize12 colorB4272B" style="margin-left: 110px;">
                実際入金額と差異がありますと、入金反映が遅くなる原因になります。
              </div>
            </div>
            <div class="rakutenBankLeft" style="margin-top: 40px">
              <div class="flexAndCenter paypalInput">
                <div class="flex bankLabel">{{ $fanyi('入金備考欄') }}：</div>
                <el-input v-model="rakutenBankRemark"/>
              </div>
              <div class="fontSize12 colorB4272B" style="margin-top: 9px;margin-left: 110px;">{{
                  $fanyi('※入金報告の備考欄で質問を入れないでください')
                }}。
              </div>
              <div class="fontSize12 colorB4272B" style="margin-left: 110px;">{{
                  $fanyi('担当が異なりますので伝わりません')
                }}。
              </div>
            </div>
            <div class="submitBtn" @click="bankPayBalance()">{{ $fanyi('提出') }}</div>
          </div>
          <div style="margin-left: 184px">
            <div>
              <div class="flexAndCenter">
                <div class="labelName">銀行名：</div>
                <div>ゆうちょ銀行</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店番号：</div>
                <div>四〇八支店</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座番号：</div>
                <div>5631876</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座名義：</div>
                <div>ラクマート（カ</div>
              </div>
              <div style="margin-top: 30px">
                <div>ゆうちょ銀行からのお振込</div>
                <div>14040-56318761</div>
              </div>
            </div>
            <div class="colorB4272B" style="margin-top:59px">1、銀行入金する金額を入力してください</div>
            <div class="colorB4272B">2、銀行入金額は見積り総額と同じまたは上回るように入力と実際振り込
              みをお願い致します。
            </div>
            <div class="colorB4272B">3、入金名義について、必ず入金報告提出時のと同じなるようにお願い致
              します、違う場合、特定できず、入金反映が著しく遅くなってしまいます。
            </div>
          </div>
        </div>
        <div v-show="selectBankIndex===3" class="rakutenBankRecharge flex">
          <div>
            <div class="rakutenBankLeft">
              <div class="flexAndCenter">
                <div class="flex bankLabel">
                  <div class="redRequiredIcon">*</div>
                  <div>{{ $fanyi('着金予定日') }}：</div>
                </div>
                <el-date-picker v-model="pay_reach_date" type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </div>
              <div v-show="rakutenBankDateRequiredError===true" class="rakutenBankDateRequiredText">
                {{ $fanyi('*ついている項目は必ずご記入ください') }}
              </div>
            </div>
            <div class="rakutenBankLeft">
              <div class="flexAndCenter">
                <div style="margin-top: 8px">
                  <div class="flex bankLabel">
                    <div class="redRequiredIcon">*</div>
                    <div>{{ $fanyi('入金名義') }}：</div>
                  </div>
                  <div class="bankLabel" style="padding-right: 10px">{{ $fanyi('カタカナ記載') }}</div>
                </div>
                <el-autocomplete
                    v-model="rakutenName"
                    :fetch-suggestions="querySearchAsync"
                ></el-autocomplete>
              </div>
            </div>
            <div class="rakutenBankLeft" style="margin-top: 15px">
              <div class="flexAndCenter paypalInput">
                <div class="flex bankLabel">
                  <div class="redRequiredIcon">*</div>
                  <div>{{ $fanyi('入金額') }}：</div>
                </div>
                <el-input v-model.number="rakutenBankPrice" @input="extractAmountVerify"
                          @keydown.native="channelInputLimit">
                  <template slot="append">円</template>
                </el-input>
              </div>
              <div class="fontSize12 colorB4272B" style="margin-top: 9px;margin-left: 110px;">
                入金報告で、実際に入金する金額をご記入下さい。
              </div>
              <div class="fontSize12 colorB4272B" style="margin-left: 110px;">
                実際入金額と差異がありますと、入金反映が遅くなる原因になります。
              </div>
            </div>
            <div class="rakutenBankLeft" style="margin-top: 40px">
              <div class="flexAndCenter">
                <div class="flex bankLabel">{{ $fanyi('入金備考欄') }}：</div>
                <el-input v-model="rakutenBankRemark"/>
              </div>
              <div class="fontSize12 colorB4272B" style="margin-top: 9px;margin-left: 110px;">{{
                  $fanyi('※入金報告の備考欄で質問を入れないでください')
                }}。
              </div>
              <div class="fontSize12 colorB4272B" style="margin-left: 110px;">{{
                  $fanyi('担当が異なりますので伝わりません')
                }}。
              </div>
            </div>
            <div class="submitBtn" @click="bankPayBalance()">{{ $fanyi('提出') }}</div>
          </div>
          <div style="margin-left: 184px">
            <div>
              <div class="flex">
                <div class="labelName">銀行名：</div>
                <div>
                  <div>paypay銀行</div>
                  <div>（旧）ジャパンネット銀行</div>
                </div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店番号：</div>
                <div>005</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">支店名：</div>
                <div>ビジネス営業部</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">預金種目：</div>
                <div>普通預金</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座番号：</div>
                <div>1928861</div>
              </div>
              <div class="flexAndCenter">
                <div class="labelName">口座名義：</div>
                <div>ラクマート（カ）ジャパン</div>
              </div>
            </div>
            <div class="colorB4272B" style="margin-top:59px">1、銀行入金する金額を入力してください</div>
            <div class="colorB4272B">2、銀行入金額は見積り総額と同じまたは上回るように入力と実際振り込
              みをお願い致します。
            </div>
            <div class="colorB4272B">3、入金名義について、必ず入金報告提出時のと同じなるようにお願い致
              します、違う場合、特定できず、入金反映が著しく遅くなってしまいます。
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectBankIndex: 0,
      paypalPrice: 0,
      paypalRechargeValue: null,
      rakutenBankDate: '',
      rakutenName: '',
      rakutenBankRemark: '',
      pay_reach_date: '',
      rakutenBankDateRequiredError: false,
      ruleForm: {
        bill_method: 2,
        pay_realname: "",
        pay_amount: "",
        pay_remark: "",
        pay_date: "",
        pay_image_url: "",
      },
      rakutenBankPrice: '',
      bankList: [{
        bankLogoUrl: require('../../../../assets/319x110_a.png'),
      }, {
        bankLogoUrl: require('../../../../assets/bank1.png'),
      }, {
        bankLogoUrl: require('../../../../assets/bank2.png'),
      }, {
        bankLogoUrl: require('../../../../assets/bank3.png'),
      }],
      count: 3,
      timer: null,
      paypalCount: 3,
      paypalTimer: null,
      payAccountList: [],
      selectPayAccountList: [],
      rules: {
        pay_date: [
          {
            required: true,
            message: this.$fanyi("汇款日期不能为空"),
            trigger: "blur",
          },
        ],
        pay_amount: [
          {
            required: true,
            message: this.$fanyi("汇款金额不能为空"),
            trigger: "blur",
          },
        ],
        pay_remark: [
          {
            required: true,
            message: this.$fanyi("备注不能为空"),
            trigger: "blur",
          },
        ],
        pay_realname: [
          {
            required: true,
            message: this.$fanyi("汇款人不能为空"),
            trigger: "blur",
          },
        ],
        pay_image_url: [
          {
            required: true,
            message: this.$fanyi("汇款图片不能为空"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getNowTime();
    this.$api.japanGetUserInfo().then((res) => {
      this.$store.commit("userData", res.data);
      this.payAccountList = res.data.user_pay_account;
    });
  },
  methods: {
    //银行汇款充值余额
    bankPayBalance() {
      if (this.pay_reach_date === '' || this.rakutenName === '' || this.rakutenBankPrice === '') {
        this.$message.error(this.$fanyi('*ついている項目は必ずご記入ください'));
        return
      }
      if (this.count === 3 && this.timer == null) {
        this.timer = setInterval(() => {
          this.count--;
          if (this.count <= 0) {
            this.count = 3;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
        this.$api.capitalBankUpBalance({
          pay_bank_method: this.selectBankIndex + 1,
          pay_reach_date: this.pay_reach_date,
          pay_date: this.rakutenBankDate,
          pay_name: this.rakutenName,
          pay_amount: this.rakutenBankPrice,
          pay_remark: this.rakutenBankRemark
        }).then((res) => {
          let obj = {
            type: 'bank',
            serial_number: res.data.serial_number,
            priceType: 'enter',
            pay_amount_jpy: res.data.pay_amount_jpy,
          };
          this.$store.commit("updatePayDetails", {obj: obj});
          let routeData = this.$router.resolve({
            path: "/balanceMaybeBankPaymentSuccess",
          });
          setTimeout(() => {
            window.open(routeData.href, "_blank");
          }, 500)
        });
      }
    },
    //提现金额格式校验
    extractAmountVerify(val) {
      this.rakutenBankPrice = this.$fun.priceReplace(val)
    },
    getNowTime() {
      var now = new Date()
      this.rakutenBankDate = now.toLocaleString();
    },
    //更新paypal请求料金金额
    paypalRechargeValueChange(e) {
      this.paypalRechargeValue = e.replace(/\./g, '').replace(/^0+/g, '');
      this.paypalRechargeValue = this.$fun.priceReplace(e);
      this.paypalPrice = Number(this.paypalRechargeValue) + Number((this.paypalRechargeValue * this.$store.state.userInfo.PayPal_rate).toFixed(0));
      this.$forceUpdate();
    },
    //更新选中银行
    updateBankIndex(index) {
      this.selectBankIndex = index;
      this.rakutenName = '';
      if (index > 0) {
        this.selectPayAccountList = [];
        for (let i = 0; i < this.payAccountList.length; i++) {
          if (this.payAccountList[i].bill_method === index + 1) {
            this.selectPayAccountList.push({
              value: this.payAccountList[i].pay_account,
              is_default: this.payAccountList[i].is_default,
              bill_method: this.payAccountList[i].bill_method
            })
            if (this.payAccountList[i].is_default == 1) {
              this.rakutenName = this.payAccountList[i].pay_account
            }
          }
        }
      }
      this.$forceUpdate();
      clearInterval(this.timer);
      this.timer = null;
      this.count = 3;
    },
    querySearchAsync(queryString, cb) {
      var results = this.selectPayAccountList;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 100 * Math.random());
    },
    //输入内容过滤
    channelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" ||
          key === "." ||
          key === "。" ||
          key == "-" ||
          key == "-" ||
          key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    //获取paypal支付链接
    getPayPalPayLink() {
      if (this.paypalRechargeValue != null) {
        if (this.paypalCount === 3 && this.paypalTimer == null) {
          this.paypalTimer = setInterval(() => {
            this.paypalCount--;
            if (this.paypalCount <= 0) {
              this.paypalCount = 3;
              clearInterval(this.paypalTimer);
              this.paypalTimer = null;
            }
          }, 1000);
          this.$api.payPalPayBalance({
            pay_amount: this.paypalRechargeValue
          }).then((res) => {
            if (res.code != 0) {
              this.$message.error(this.$fanyi(res.msg));
              return
            }
            var a = document.createElement("a");
            a.setAttribute("href", res.data.pay_url);
            let clickEvent = document.createEvent("MouseEvents");
            clickEvent.initEvent("click", true, true);
            a.dispatchEvent(clickEvent);
          });
        }
      } else {
        this.$message.error("金額をご記入ください");
      }
    }
  },
};
</script>
<style lang='scss' scoped>
@import "../../../../css/mixin.scss";

.main {
  header {
    background: #fff;
    border-radius: 10px;

    .headerTop {
      height: 73px;
      line-height: 73px;
      padding-left: 26px;
      font-weight: bold;
      font-size: 20px;
      border-bottom: 1px solid #DFDFDF;
    }

    .headerFooter {
      .withdrawDepositText {
        display: flex;
        justify-content: flex-end;
        margin: 14px 25px 30px 0;
        color: #B4272B;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
      }

      .userPriceList {
        padding: 0 50px 60px;
        justify-content: space-between;

        .userPriceContainer {
          width: 280px;
          height: 46px;

          .userPriceLabel {
            color: #000000;
            margin-bottom: 13px;
          }

          .userPriceValue {
            div:first-child {
              color: #B4272B;
              font-weight: bold;
              font-size: 18px;
              margin-right: 8px;
            }

            div:last-child {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  footer {
    background: #fff;
    border-radius: 10px;
    margin-top: 26px;

    .footerTop {
      height: 73px;
      line-height: 73px;
      padding-left: 26px;
      font-weight: bold;
      font-size: 20px;
      border-bottom: 1px solid #DFDFDF;
    }

    .footerCenter {
      margin-top: 57px;
      padding: 0 26px;
      justify-content: space-between;

      div {
        width: 230px;
        height: 90px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 10px;
        justify-content: center;
      }

      .redBorderBankContainer {
        border: 2px solid #B4272B !important;
        position: relative;
      }

      div:first-child {
        img {
          width: 95%;
          height: 100%;
        }
      }

      div:nth-child(2n) {
        img {
          width: 154px;
          height: 23px;
        }
      }

      div:nth-child(3n) {
        img {
          width: 154px;
          height: 29px;
        }
      }

      div:last-child {
        img {
          width: 154px;
          height: 26px;
        }
      }

      .selectBankIcon {
        width: 30px;
        height: 30px;
        background: #B4272B;
        border-radius: 10px 0 10px 0;
        justify-content: center;
        position: absolute;
        bottom: -2px;
        right: -2px;
        color: #fff;

        img {
          width: 15px !important;
          height: 19px !important;
        }
      }
    }

    .footerBottom {
      padding: 50px 26px 26px;

      .paypalInput {
        width: 280px;

        .el-input {
          height: 34px;
          position: relative;

          /deep/ .el-input__inner {
            width: 280px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 3px;
            margin: 0;
          }

          /deep/ .el-input-group__append {
            background-color: #fff;
            position: absolute;
            right: 0;
            height: 34px;
            top: 0;
            padding-right: 30px;
            line-height: 34px;
          }
        }

        .PayPalBtn {
          margin-top: 25px;
          width: 280px;
          height: 34px;
          background: #B4272B;
          border-radius: 3px;
          line-height: 34px;
          text-align: center;
          color: #FFFFFF;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .labelName {
        text-align: right;
        width: 80px;
      }

      .submitBtn {
        width: 280px;
        height: 34px;
        background: #B4272B;
        border-radius: 3px;
        line-height: 34px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin-top: 49px;
        margin-left: 110px;
      }

      .paypalRecharge {

        .paypalRechargePrice {
          margin-left: 9px;

          .paypalRechargePriceTop {
            div:first-child {
              min-width: 80px;
            }

            div:last-child {
              color: #B4272B;
            }
          }
        }

        .errorText {
          font-size: 12px;
          margin-right: 4px;
          color: #B4272B;
        }

        .paypalRechargeInstructions {
          margin-left: 106px;

          div {
            color: #B4272B;
            width: 514px;
          }
        }
      }

      .bankLabel {
        width: 110px;
        min-width: 110px;
        text-align: right;
        justify-content: flex-end;
      }

      .rakutenBankRecharge {
        .redRequiredIcon {
          color: #B4272B;
        }

        .rakutenBankLeft {
          height: 60px;

          /deep/ .el-input__inner {
            width: 280px;
            height: 34px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            border-radius: 3px;
          }

          .rakutenBankDateRequiredText {
            margin-top: 5px;
            color: #B4272B;
            font-size: 12px;
            margin-left: 110px;
          }

          /deep/ .el-input__icon {
            line-height: 34px;
          }
        }
      }
    }
  }
}

</style>
